















































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import api from "@/core/utils/api";
import UserItem from "../components/UserItem.vue";
import { AdminUser, RoleClaims, Roles, SelectItem } from "@/core/models";

const MINUTES_IN_DAY = 24 * 60;
const MINUTES_IN_MONTH = 30 * 24 * 60;

@Component({ components: { UserItem } })
export default class Users extends Vue {
  @Getter("profile/getUserEmail") currentUserEmail!: string;
  @Action("profile/addUsersFromExcel") addUsersFromFileAction!: (
    file: File,
  ) => Promise<void>;

  search = "";
  loading = false;
  type: "active" | "inactive" | "both" | "none" = "both";
  users: AdminUser[] = [];
  file: File | null = null;

  get filteredUsers() {
    if (!this.users) return [];

    const base = this.users.filter(x => {
      if (this.type === "both") return true;
      else if (this.type === "active") return x.isActive;
      else if (this.type === "inactive") return !x.isActive;
      else return false;
    });

    if (!this.search) return base;
    return base.filter(x =>
      `${x.email};${x.username};${x.roles}`.includes(this.search),
    );
  }

  async getUsers() {
    this.loading = true;
    try {
      this.users = (await api.get("/api/Users/GetAllUsers")) as AdminUser[];
    } catch (error) {
      this.users = [];
      console.log(error);
    }
    this.loading = false;
  }

  toggle(type: "active" | "inactive" | "both") {
    switch (type) {
      case "active": {
        if (this.type === "inactive") this.type = "both";
        else if (this.type === "both") this.type = "inactive";
        else if (this.type === "none") this.type = "active";
        else this.type = "none";
        break;
      }
      case "inactive": {
        if (this.type === "active") this.type = "both";
        else if (this.type === "both") this.type = "active";
        else if (this.type === "none") this.type = "inactive";
        else this.type = "none";
        break;
      }
    }
  }

  async upload() {
    if (!this.file) return;
    this.addUsersFromFileAction(this.file);
  }

  handleFileChange(file: File) {
    if (
      !(
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    )
      return;
    this.file = file;
  }

  selectedUser: AdminUser | null = null;
  isLoading(user: AdminUser) {
    if (!this.selectedUser) return;
    return (
      this.selectedUser.email === user.email &&
      (this.editingRoles ||
        this.togglingActive ||
        this.verifyLoading ||
        this.editingPermissions)
    );
  }
  // Active status changing
  activeDialog = false;
  togglingActive = false;
  activeDuration = -1;
  get timeItems() {
    return [
      { text: this.$t("activeUser.indef"), value: -1 },
      { text: "15" + this.$t("activeUser.mins"), value: 15 },
      { text: "30" + this.$t("activeUser.mins"), value: 30 },
      { text: "1" + this.$t("activeUser.hours"), value: 60 },
      { text: "3" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 8 },
      { text: "6" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 4 },
      { text: "12" + this.$t("activeUser.hours"), value: MINUTES_IN_DAY / 2 },
      { text: "1" + this.$t("activeUser.days"), value: MINUTES_IN_DAY },
      { text: "5" + this.$t("activeUser.days"), value: 4 * MINUTES_IN_DAY },
      { text: "10" + this.$t("activeUser.days"), value: 10 * MINUTES_IN_DAY },
      { text: "15" + this.$t("activeUser.days"), value: 15 * MINUTES_IN_DAY },
      { text: "30" + this.$t("activeUser.days"), value: MINUTES_IN_MONTH },
      { text: "2" + this.$t("activeUser.months"), value: 2 * MINUTES_IN_MONTH },
      {
        text: "12" + this.$t("activeUser.months"),
        value: 12 * MINUTES_IN_MONTH,
      },
    ] as SelectItem<number>[];
  }
  handleToggleActive(user: AdminUser) {
    this.activeDialog = true;
    this.selectedUser = user;
  }
  async toggleActive() {
    if (!this.selectedUser) return;
    const email = this.selectedUser.email;
    const isActive = this.selectedUser.isActive;
    const duration = this.activeDuration;

    this.togglingActive = true;
    try {
      const { activeUntil } = (await api.put("/api/Users/ToggleActive", {
        user: email,
        duration,
      })) as any;
      const idx = this.users.findIndex(x => x.email === email);
      const date =
        duration === -1 ? "" : new Date(activeUntil ?? "").toUTCString();
      if (idx !== -1) {
        const updatedArr = this.users.slice(0);
        updatedArr[idx] = JSON.parse(
          JSON.stringify({
            ...updatedArr[idx],
            isActive: !isActive,
            activeUntil: date,
          } as AdminUser),
        );
        this.users = updatedArr;
      }
    } catch (error) {
      console.log("Could not toggle", error);
    }
    this.togglingActive = false;
    this.activeDialog = false;
  }
  @Watch("activeDialog")
  activeDialogChanged() {
    if (this.activeDialog) return;
    this.selectedUser = null;
    this.activeDuration = -1;
  }

  // Verifying email
  verifyLoading = false;
  handleVerifyEmail(user: AdminUser) {
    this.selectedUser = user;
    this.verifyEmail();
  }
  async verifyEmail() {
    const user = this.selectedUser;
    if (!user) return;

    this.verifyLoading = true;
    try {
      await api.get(`/api/Admin/VerifyEmail/${user.email}`);
      const idx = this.users.indexOf(user);
      this.users[idx].emailVerified = true;
    } catch (error) {
      console.log(error);
    }
    this.verifyLoading = false;
    this.selectedUser = null;
  }

  /////////////////////////////////////////////////////
  // Role controller
  editingRoles = false;
  rolesDialog = false;
  removeRoleDialog = false;
  modifyRoleDialog = false;
  newRoles: Roles[] = [];

  get stdRoles(): SelectItem[] {
    return [
      {
        text: this.$t("roles.user").toString(),
        value: "user",
        disabled: this.newRoles.some(x => x.name === "user"),
      },
      {
        text: this.$t("roles.subscriberFree").toString(),
        value: "subscriber-free",
        disabled: this.newRoles.some(x => x.name === "subscriber-free"),
      },
      {
        text: this.$t("roles.subscriberBasic").toString(),
        value: "subscriber-basic",
        disabled: this.newRoles.some(x => x.name === "subscriber-basic"),
      },
      {
        text: this.$t("roles.subscriberStandard").toString(),
        value: "subscriber-standard",
        disabled: this.newRoles.some(x => x.name === "subscriber-standard"),
      },
      {
        text: this.$t("roles.subscriberPremium").toString(),
        value: "subscriber-premium",
        disabled: this.newRoles.some(x => x.name === "subscriber-premium"),
      },
      {
        text: this.$t("roles.subscriberEnterprise").toString(),
        value: "subscriber-enterprise",
        disabled: this.newRoles.some(x => x.name === "subscriber-enterprise"),
      },
      {
        text: this.$t("roles.admin").toString(),
        value: "admin",
        disabled: this.newRoles.some(x => x.name === "admin"),
      },
    ];
  }
  addRole(roleName: string) {
    if (!this.selectedUser) return;
    this.newRoles.push({
      name: roleName,
      removeRoleDialog: false,
    });
  }
  removeRole(name: string) {
    if (!this.selectedUser) return;
    if (!this.newRoles.length) return;
    const idx = this.newRoles.findIndex(x => x.name === name);
    if (idx !== -1) {
      this.newRoles.splice(idx, 1);
      this.newRoles[idx].removeRoleDialog = false;
    }
  }
  handleRoles(user: AdminUser) {
    this.selectedUser = user;
    this.newRoles = JSON.parse(JSON.stringify(user.roles));
    console.log("this.newRoles: ", this.newRoles);
    this.rolesDialog = true;
  }
  getRoleName(roleName: string) {
    const role = this.stdRoles.find(x => x.value === roleName);
    return role ? role!.text : "Unknown Role";
  }
  async confirmRoles() {
    // check user
    const user = this.selectedUser;
    if (!user) return;

    // init
    this.rolesDialog = false;
    this.editingRoles = true;

    try {
      // send data
      const end = "/api/Admin/EditRoles";
      const data = { email: user.email, roles: this.newRoles };
      await api.post(end, data);

      // update user
      const idx = this.users.findIndex(x => x.email === user.email);
      if (idx !== -1) {
        const copy = JSON.parse(JSON.stringify(this.newRoles));
        this.users[idx].roles = copy;
      }
    } catch (error) {
      console.log(error);
    }

    // clear local stuff
    this.newRoles = [];
    this.editingRoles = false;
  }

  //////////////////////////////////////////////////////////
  // Permission controller
  editingPermissions = false;
  permissionsDialog = false;
  removePermissionDialog: boolean = false;
  modifyPermissionDialog: boolean = false;
  permissionValue: string = "0";
  newPermissions: RoleClaims[] = [];
  get stdPermissions(): SelectItem[] {
    return [
      {
        text: this.$t("permissions.teacher").toString(),
        value: "teacher:default",
        disabled: this.newPermissions.some(x => x.type === "teacher:default"),
      },
      {
        text: this.$t("permissions.student").toString(),
        value: "student:default",
        disabled: this.newPermissions.some(x => x.type === "student:default"),
      },
      {
        text: this.$t("permissions.session_training").toString(),
        value: "session_training:default",
        disabled: this.newPermissions.some(
          x => x.type === "session_training:default",
        ),
      },
      {
        text: this.$t("permissions.can_share_with_all").toString(),
        value: "can_share_with_all:default",
        disabled: this.newPermissions.some(
          x => x.type === "can_share_with_all:default",
        ),
      },
      {
        text: this.$t("permissions.seminar").toString(),
        value: "seminar:default",
        disabled: this.newPermissions.some(x => x.type === "seminar:default"),
      },
      {
        text: this.$t("permissions.upload_audio").toString(),
        value: "upload:audio",
        disabled: this.newPermissions.some(x => x.type === "upload:audio"),
      },
      {
        text: this.$t("permissions.upload_video").toString(),
        value: "upload:video",
        disabled: this.newPermissions.some(x => x.type === "upload:video"),
      },
      {
        text: this.$t(
          "feature_perms.live_transcription_relaxed_seconds",
        ).toString(),
        value: "live_transcription:relaxed",
        disabled: this.newPermissions.some(
          x =>
            x.type === "live_transcription:relaxed" && x.claimType === "user",
        ),
      },
      {
        text: this.$t(
          "feature_perms.live_transcription_fast_seconds",
        ).toString(),
        value: "live_transcription:fast",
        disabled: this.newPermissions.some(
          x => x.type === "live_transcription:fast" && x.claimType === "user",
        ),
      },
      {
        text: this.$t("feature_perms.analysis_relaxed_count").toString(),
        value: "analysis:relaxed",
        disabled: this.newPermissions.some(
          x => x.type === "analysis:relaxed" && x.claimType === "user",
        ),
      },
      {
        text: this.$t("feature_perms.analysis_fast_count").toString(),
        value: "analysis:fast",
        disabled: this.newPermissions.some(
          x => x.type === "analysis:fast" && x.claimType === "user",
        ),
      },
      {
        text: this.$t("feature_perms.ai_feedback_relaxed_count").toString(),
        value: "ai_feedback:relaxed",
        disabled: this.newPermissions.some(
          x => x.type === "ai_feedback:relaxed" && x.claimType === "user",
        ),
      },
      {
        text: this.$t("feature_perms.ai_feedback_fast_count").toString(),
        value: "ai_feedback:fast",
        disabled: this.newPermissions.some(
          x => x.type === "ai_feedback:fast" && x.claimType === "user",
        ),
      },
      {
        text: this.$t("permissions.public_group_limited").toString(),
        value: "public_group:limited",
        disabled: this.newPermissions.some(
          x => x.type === "public_group:limited",
        ),
      },
      {
        text: this.$t("permissions.public_group_full").toString(),
        value: "public_group:full",
        disabled: this.newPermissions.some(x => x.type === "public_group:full"),
      },
      {
        text: this.$t("permissions.b2b_group").toString(),
        value: "b2b_group:default",
        disabled: this.newPermissions.some(x => x.type === "b2b_group:default"),
      },
      {
        text: this.$t("permissions.tutorial_basic").toString(),
        value: "tutorial:basic",
        disabled: this.newPermissions.some(x => x.type === "tutorial:basic"),
      },
      {
        text: this.$t("permissions.tutorial_advanced").toString(),
        value: "tutorial:advanced",
        disabled: this.newPermissions.some(x => x.type === "tutorial:advanced"),
      },
      {
        text: this.$t("permissions.tutorial_expert").toString(),
        value: "tutorial:expert",
        disabled: this.newPermissions.some(x => x.type === "tutorial:expert"),
      },
      {
        text: this.$t("permissions.coach_booking_review").toString(),
        value: "coach_booking:review",
        disabled: this.newPermissions.some(
          x => x.type === "coach_booking:review",
        ),
      },
      {
        text: this.$t("permissions.coach_booking_1on1").toString(),
        value: "coach_booking:1on1",
        disabled: this.newPermissions.some(
          x => x.type === "coach_booking:1on1",
        ),
      },
      {
        text: this.$t("permissions.challenge").toString(),
        value: "challenge:default",
        disabled: this.newPermissions.some(x => x.type === "challenge:default"),
      },
      {
        text: this.$t("permissions.course").toString(),
        value: "course:default",
        disabled: this.newPermissions.some(x => x.type === "course:default"),
      },
      {
        text: this.$t("permissions.vr_mode").toString(),
        value: "vr_mode:default",
        disabled: this.newPermissions.some(x => x.type === "vr_mode:default"),
      },
      {
        text: this.$t("permissions.sso").toString(),
        value: "sso:default",
        disabled: this.newPermissions.some(x => x.type === "sso:default"),
      },
      {
        text: this.$t("permissions.custom_idp").toString(),
        value: "custom_idp:default",
        disabled: this.newPermissions.some(
          x => x.type === "custom_idp:default",
        ),
      },
      {
        text: this.$t("permissions.external_api").toString(),
        value: "external_api:default",
        disabled: this.newPermissions.some(
          x => x.type === "external_api:default",
        ),
      },
      {
        text: this.$t("permissions.onboarding").toString(),
        value: "onboarding:default",
        disabled: this.newPermissions.some(
          x => x.type === "onboarding:default",
        ),
      },
      {
        text: this.$t("permissions.support_tech").toString(),
        value: "support:tech",
        disabled: this.newPermissions.some(x => x.type === "support:tech"),
      },
      {
        text: this.$t("permissions.support_dev").toString(),
        value: "support:dev",
        disabled: this.newPermissions.some(x => x.type === "support:dev"),
      },
      {
        text: this.$t("permissions.sla").toString(),
        value: "sla:default",
        disabled: this.newPermissions.some(x => x.type === "sla:default"),
      },
      {
        text: this.$t("permissions.redundant_service").toString(),
        value: "redundant_service:default",
        disabled: this.newPermissions.some(
          x => x.type === "redundant_service:default",
        ),
      },
    ];
  }

  claimsWithCount = [
    "live_transcription:relaxed",
    "live_transcription:fast",
    "ai_feedback:relaxed",
    "ai_feedback:fast",
    "analysis:relaxed",
    "analysis:fast",
  ];

  addPermission(type: string) {
    if (!this.selectedUser) return;
    this.newPermissions.push({
      type,
      value: this.claimsWithCount.includes(type) ? "0" : "",
      removePermissionDialog: false,
      modifyPermissionDialog: false,
      claimType: "user",
    });
    this.newPermissions.sort((a: any, b: any) => {
      return (
        b.claimType.localeCompare(a.claimType) || a.type.localeCompare(b.type)
      );
    });
  }
  removePermission(type: string) {
    if (!this.selectedUser) return;
    if (!this.newPermissions.length) return;
    const idx = this.newPermissions.findIndex(
      x => x.removePermissionDialog && x.type === type,
    );
    if (idx !== -1) {
      this.newPermissions[idx].removePermissionDialog = false;
      this.newPermissions.splice(idx, 1);
    }
  }
  modifyPermission(type: string) {
    if (!this.selectedUser) return;
    const idx = this.newPermissions.findIndex(
      x => x.modifyPermissionDialog && x.type === type,
    );
    if (idx !== -1) {
      this.newPermissions[idx].modifyPermissionDialog = false;
      this.newPermissions[idx].value = this.permissionValue;
    }
  }
  handlePermissions(user: AdminUser) {
    api
      .get(`/api/Admin/${user.email}/GetUserRoleClaims`)
      .then((roleClaims: any) => {
        const inheritedPermissions = roleClaims.map((roleClaim: any) => {
          roleClaim.claimType = "role";
          return roleClaim;
        });
        this.selectedUser = user;
        api
          .get(`/api/Admin/${user.email}/GetUserClaims`)
          .then((userClaims: any) => {
            const precedencePermissions = userClaims.map((userClaim: any) => {
              userClaim.claimType = "user";
              return userClaim;
            });

            user.permissions = [
              ...precedencePermissions.sort((a: any, b: any) =>
                a.type.localeCompare(b.type),
              ),
              ...inheritedPermissions,
            ];
            this.newPermissions = user.permissions;
            this.permissionsDialog = true;
          })
          .catch(error => {
            console.error("Error while loading user claims:", error);
          });
      })
      .catch(error => {
        console.error("Error while loading role claims:", error);
      });
  }
  getPermissionName(type: string) {
    const permission = this.stdPermissions.find(x => x.value === type);
    return permission ? permission.text : "Unknown Permission";
  }

  async confirmPermissions() {
    // check user
    const user = this.selectedUser;
    if (!user) return;

    // init
    this.permissionsDialog = false;
    this.editingPermissions = true;

    try {
      // only submit user claims
      const updatedPermissions = this.newPermissions.filter(
        p => p.claimType === "user",
      );
      // send data
      const end = "/api/Admin/EditFeatures";
      const data = { email: user.email, permissions: updatedPermissions };
      await api.post(end, data);

      // update user
      const idx = this.users.findIndex(x => x.email === user.email);
      if (idx !== -1)
        this.users[idx].permissions = JSON.parse(
          JSON.stringify(this.newPermissions),
        );
    } catch (error) {
      console.log(error);
    }

    // clear local stuff
    this.newPermissions = [];
    this.editingPermissions = false;
  }

  created() {
    this.getUsers();
  }
}
